import {ProSidebar, SidebarContent} from "react-pro-sidebar";
import {useAuth} from "@/services/Auth";
import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Private from "../router/Private";
import LoadingButton from "../shared/components/LoadingButton";
import LOCATION from '../constants/Location'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartColumn, faListAlt, faMugHot, faRoute, faUser, faPoundSign, faXmark} from '@fortawesome/free-solid-svg-icons'
import BagIcon from '../shared/components/Icons/BagIcon'
import GraphStarIcon from '../shared/components/Icons/GraphStarIcon'
import SunIcon from "../shared/components/Icons/SunIcon";


function Aside({collapsed, toggled, handleToggleSidebar}) {
    const auth = useAuth();
    const {t} = useTranslation();
    const location = useLocation();

    let privateRoutes = Private;

    const [loading, setLoading] = useState(false);
    const [routes, setRoutes] = useState([]);

    const logout = () => {
        setLoading(true);
        auth.logout()
            .then(response => {
                setLoading(false);
                window.location.href="/login";
            })
            .catch(error => {
                setLoading(false);
            })
    }

    useEffect(() => {
        setRoutes(() => privateRoutes.filter(_ => _.main))
    }, [])
    return (
        <ProSidebar
            collapsed={collapsed}
            toggled={toggled}
            breakPoint="lg"
            onToggle={handleToggleSidebar}
        >
            <SidebarContent>
                <div className="d-flex flex-column sidebar-content">
                    <ul className="list-group px-2 py-4">
                        <Link
                          to={LOCATION.USERS.LIST.path}
                          className={`list-group-item py-3 px-2 border-0 ${LOCATION.USERS.LIST.path === location.pathname ? 'active-link' : ''}`}
                        >
                            <FontAwesomeIcon className="text-primary" size="lg" icon={faUser}/>
                            <span className="menu-name ms-3 heading-text fw-normal">{t('pages.users.title')}</span>
                        </Link>
                        <Link
                          to={LOCATION.DEALS.LIST.path}
                          className={`list-group-item py-3 px-2 border-0 ${LOCATION.DEALS.LIST.path === location.pathname ? 'active-link' : ''}`}
                        >
                            <BagIcon size="20" color="#F2BD1E"/>
                            <span className="menu-name ms-3 heading-text fw-normal">{t('pages.deals.title')}</span>
                        </Link>
                        <Link
                          to={LOCATION.HOLIDAY_TYPES.LIST.path}
                          className={`list-group-item py-3 px-2 border-0 ${LOCATION.HOLIDAY_TYPES.LIST.path === location.pathname ? 'active-link' : ''}`}
                        >
                            <FontAwesomeIcon size="lg" className="text-primary" icon={faMugHot}/>
                            <span className="menu-name ms-3 heading-text fw-normal">{t('pages.deals.holidays')}</span>
                        </Link>
                        <Link
                          to={LOCATION.DEALS_WEEK.LIST.path}
                          className={`list-group-item py-3 px-2 border-0 ${LOCATION.DEALS_WEEK.LIST.path === location.pathname ? 'active-link' : ''}`}
                        >
                            <GraphStarIcon size="20" color="#F2BD1E"/>
                            <span className="menu-name ms-3 heading-text fw-normal">{t('pages.deals.top_15')}</span>
                        </Link>
                        <Link
                            to={LOCATION.DESTINATION_SPECIFIC_DEALS.LIST.path}
                            className={`list-group-item py-3 px-2 border-0 ${LOCATION.DESTINATION_SPECIFIC_DEALS.LIST.path === location.pathname ? 'active-link' : ''}`}
                        >
                            <SunIcon size="20" color="#F2BD1E"/>
                            <span className="menu-name ms-3 heading-text fw-normal">{t('pages.deals.destination_specific_deals')}</span>
                        </Link>
                        <Link
                          to={LOCATION.DESTINATIONS.LIST.path}
                          className={`list-group-item py-3 px-2 border-0 ${LOCATION.DESTINATIONS.LIST.path === location.pathname ? 'active-link' : ''}`}
                        >
                            <FontAwesomeIcon className="text-primary" size="lg" icon={faRoute}/>
                            <span className="menu-name ms-3 heading-text fw-normal">{t('pages.locations.title')}</span>
                        </Link>
                        <Link
                          to={LOCATION.TRIPS.REQUESTS.path}
                          className={`list-group-item py-3 px-2 border-0 ${LOCATION.TRIPS.REQUESTS.path === location.pathname ? 'active-link' : ''}`}
                        >
                            <FontAwesomeIcon className="text-primary" size="lg" icon={faListAlt}/>
                            <span className="menu-name ms-3 heading-text fw-normal">{t('pages.trip_requests.title')}</span>
                        </Link>
                        <Link
                            to={LOCATION.SUBSCRIPTION_MANAGEMENT.LIST.path}
                            className={`list-group-item py-3 px-2 border-0 ${LOCATION.SUBSCRIPTION_MANAGEMENT.LIST.path === location.pathname ? 'active-link' : ''}`}
                        >
                            <FontAwesomeIcon className="text-primary" size="lg" icon={faPoundSign}/>
                            <span className="menu-name ms-3 heading-text fw-normal">{t('pages.subscription_management.title')}</span>
                        </Link>
                        <Link
                            to={LOCATION.BLACKLIST_HOTELS.LIST.path}
                            className={`list-group-item py-3 px-2 border-0 ${LOCATION.BLACKLIST_HOTELS.LIST.path === location.pathname ? 'active-link' : ''}`}
                        >
                            <FontAwesomeIcon className="text-primary" size="lg" icon={faXmark}/>
                            <span className="menu-name ms-3 heading-text fw-normal">{t('pages.blacklist_hotels.title')}</span>
                        </Link>
                    </ul>
                    <div className="content-footer mt-auto p-4">
                        <div className="d-grid gap-2">
                            <LoadingButton
                                size="sm"
                                variant="dark"
                                className="text-white heading-text"
                                onSubmit={logout}
                                titleTranslationKey="common.logout"
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </SidebarContent>
        </ProSidebar>
    )
}

export default Aside;
