import {useAuth} from "../../../services/Auth";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import AdminPageHeader from "../../../layouts/components/AdminPageHeader";
import { Col, Form, Row, Button } from 'react-bootstrap';
import LOCATION from '@/constants/Location';

function BlacklistHotels() {
  const auth = useAuth()
  const history = useHistory()
  const route = useLocation()
  const {t} = useTranslation();

  const [blacklistHotels, setBlacklistHotels] = useState([]);

    useEffect(() => {
        auth.getRequest(LOCATION.BLACKLIST_HOTELS.API.path)
          .then(({data}) => {
              setBlacklistHotels(data)
          })
          .catch(error => {
            console.log(error)
          })
    }, []);

    const handleDeleteBlacklistHotel = (index) => () => {
        const blacklistHotel = blacklistHotels[index];
        if (blacklistHotel?.id) {
            auth.deleteRequest(`/blacklist-hotels/${blacklistHotel.id}`)
                .then(response => {
                    const index = blacklistHotels?.findIndex(p => p.id === blacklistHotel.id)
                    setBlacklistHotels([...blacklistHotels.slice(0, index), ...blacklistHotels.slice(index + 1)])
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            if (index > -1) {
                setBlacklistHotels([...blacklistHotels.slice(0, index), ...blacklistHotels.slice(index + 1)]);
            }
        }
    }

    const handleUpdateBlacklistHotel = (index) => () => {
        const blacklistHotel = blacklistHotels[index];
        if (blacklistHotel?.id) {
            auth.putRequest(`/blacklist-hotels/${blacklistHotel.id}`, blacklistHotel)
                .then(({data}) => {
                    setBlacklistHotels([...blacklistHotels.slice(0, index), {...data}, ...blacklistHotels.slice(index + 1)])
                    alert('Successfully updated');
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            auth.postRequest(`/blacklist-hotels`, blacklistHotel)
                .then(({data}) => {
                    setBlacklistHotels([...blacklistHotels.slice(0, index), {...data}, ...blacklistHotels.slice(index + 1)])
                    alert('Successfully created');
                })
                .catch(error => {
                    console.log(error)
                })
        }
    };

    const handleInputChange = (index, key) => (e) => {
        const blacklistHotel = blacklistHotels[index];
        blacklistHotel[key] = e.target.value;
        setBlacklistHotels([...blacklistHotels])
    }

  return (
    <div className="py-2 px-4">
      <Helmet>
        <title>{t('pages.blacklist_hotels.title')} - {t('common.app')}</title>
      </Helmet>
      <div className="my-4 d-flex justify-content-between">
        <AdminPageHeader
          mainTitle={t('pages.blacklist_hotels.title')}
        />
      </div>
      <div className="my-4 bg-white">
          <table className="table blacklist-table">
              <thead>
              <tr>
                  <th>Name Contains</th>
                  <th>Property ID</th>
                  <th>Reason</th>
                  <th></th>
              </tr>
              </thead>
              <tbody>
              {
                  blacklistHotels?.map((blacklistHotel, index) => (
                      <tr key={index}>
                          <td>
                              <Form.Group>
                                  <Form.Control
                                      type="text"
                                      placeholder="Enter name contains"
                                      value={blacklistHotel?.name_contains}
                                      onChange={handleInputChange(index, 'name_contains')}
                                      disabled={blacklistHotel?.property_id?.length > 0}
                                  />
                              </Form.Group>
                          </td>
                          <td>
                              <Form.Group>
                                  <Form.Control
                                      type="text"
                                      placeholder="Enter property id"
                                      value={blacklistHotel?.property_id}
                                      onChange={handleInputChange(index, 'property_id')}
                                      disabled={blacklistHotel?.name_contains?.length > 0}
                                  />
                              </Form.Group>
                          </td>
                          <td className="text-area">
                              <Form.Group>
                                  <Form.Control
                                      as="textarea"
                                      placeholder="Enter reason"
                                      value={blacklistHotel?.reason}
                                      onChange={handleInputChange(index, 'reason')}
                                  />
                              </Form.Group>
                          </td>
                          <td className="btn-container">
						  	<div className="d-flex flex-row gap-2 justify-content-end w-100 blacklist-btns">
                              <Button type="button" className="btn heading-text" variant="primary" onClick={handleUpdateBlacklistHotel(index)}>
                                  {blacklistHotel?.id ? 'Update' : 'Create'}
                              </Button>
                              <Button
                                  type="button"
                                  variant="danger"
								   className="btn heading-text text-white"
                                  onClick={handleDeleteBlacklistHotel(index)}
                              >
                                  Delete
                              </Button>
							  </div>
                          </td>
                      </tr>
                  ))
              }
              <tr>
                  <td/>
                  <td/>
                  <td className="text-area"/>
                  <td className="btn-container">
				  	<div className="d-flex flex-row gap-2 justify-content-end w-100 blacklist-btns">
                      <Button
                          type="button"
                          variant="primary"
						   className="btn heading-text"
                          onClick={() => setBlacklistHotels([...(blacklistHotels ?? []), {}])}
                      >
                          Add
                      </Button>
					  </div>
                  </td>
              </tr>
              </tbody>
          </table>
      </div>
    </div>
  )
}

export default BlacklistHotels;