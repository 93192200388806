import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Button, Col, Form, Row } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react'
import PageHeader from '@/layouts/components/PageHeader'
import moment from 'moment'
import { Calendar, DateObject } from 'react-multi-date-picker'
import steps from '../Request/process_steps/steps'
import LOCATION from '../../../constants/Location'
import { Link, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'

function When ({ modelEditor, handleCancel, handleConfirm }) {
  const { t } = useTranslation()

  const history = useHistory()

  let step = steps.find(_ => _.name === 'when')

  let storeValues = localStorage.getItem('trip_values') ?? null

  const [tripValues, setTripValues] = useState(storeValues ? JSON.parse(storeValues) : {});

  let nextStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/who-is-going`
  let backStepLink = tripValues?.holiday_type === 'surprise' ? `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/where-from` : `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/where-to`

  const continueActionRef = useRef(null)

  const [months, setMonths] = useState([])

  const [dateValues, setDateValues] = useState(tripValues?.when?.request_dates ?? null)

  const calendarRef = useRef()

  const [monthsDefaultCount, setMonthsDefaultCount] = useState(11)

  const schema = yup.object().shape({
    how_long: yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Duration is required'),
    by_specific_date: yup.boolean().transform((value) => (isNaN(value) ? undefined : value)),
    months: yup.array()
      .typeError('Please select your availability')
      .when(['by_specific_date'], function (by_specific_date, schema) {
        return !by_specific_date ?
          schema.min(1, t('pages.trips.form.validation_message.choose_one_month'))
            .required('Please select your availability')
          : schema.nullable()
      }),
    request_dates: yup.array()
      .typeError('Please select your availability')
      .when(['by_specific_date'], function (by_specific_date, schema) {
        return by_specific_date ?
          schema.of(yup.array().min(2, t('pages.trips.form.validation_message.choose_valid_date_range')))
            .min(1, t('pages.trips.form.validation_message.choose_one_date'))
            .required('Please select your availability')
          : schema.nullable()
      })
  })

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    unregister,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: tripValues.when,
    resolver: yupResolver(schema),
  })

  const nextStep = (values) => {
    let when = {
      how_long: values.how_long,
      by_specific_date: values.by_specific_date,
      months: values.months,
      request_dates: values.request_dates,
      allow_flexi_duration: values.allow_flexi_duration
    }

    localStorage.setItem('trip_values', JSON.stringify({ ...tripValues, ...{ when } }))
    if (!modelEditor) {
      history.push(nextStepLink)
    } else if (handleConfirm) {
      handleConfirm()
    }
  }
  const getMessage = (errorObject) => {
    let message = null;
    Object.entries(errorObject).forEach(([key, value]) => {
      message = value?.message;
    });

    return message;
  }

  const buildMonths = () => {
    let currentDate = moment()

    let months = [currentDate];

    [...Array(monthsDefaultCount)].forEach((_, index) => {
      currentDate = moment(currentDate).add(1, 'month')
      months.push(currentDate)
    })

    setMonths(months)
  }

  const bySpecificDate = watch('by_specific_date')
  const selectedMonths = watch('months')
  const dates = watch('request_dates')

  const addMonth = (selectedMonth) => {
    let monthYear = moment(selectedMonth).format(t('common.formats.month_year'))
    let monthsList = [].concat(selectedMonths)

    if (monthsList.find(month => month === monthYear)) {
      monthsList = monthsList.filter(_ => _ != monthYear)
    } else {
      monthsList.push(monthYear)
    }
    setValue('months', monthsList)
  }

  const loadMoreMonths = () => {
    let months = monthsDefaultCount
    setMonthsDefaultCount((months + 5))
  }

  const changeDateType = (value) => {
    setValue('by_specific_date', value)
    setValue('months', [])
    setValue('request_dates', [])
    setDateValues([])
  }

  useEffect(() => {
    buildMonths()
  }, [monthsDefaultCount])

  useEffect(() => {
    if (dateValues) {
      setValue('request_dates', dateValues)
    }
  }, [dateValues])

  useEffect(() => {
    if (!storeValues) {
      history.push(LOCATION.TRIPS.START_YOUR_JOURNEY.path)
    }
  }, [])

  return (
    <>

      {
        !modelEditor &&
        <>
          <PageHeader
            className="d-none d-sm-flex"
            title={t(step.title)}
            bgColor="primary"
            textColor="dark"
            prefixElements={[]}
            suffixElements={[]}
          />
          <div className="w-100 sm-bg d-block d-sm-none" style={
            {
              backgroundImage: `url("/assets/images/bg/${step.bg_sm}")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          }>
          </div>
          <PageHeader
            className="d-block d-sm-none"
            title={t(step.title)}
            bgColor="dark"
            textColor="white"
          />
        </>
      }

      <div className="w-100 h-100 d-flex justify-content-center align-items-sm-start" style={
        !modelEditor ? {
          backgroundImage: `url("/assets/images/bg/${step.bg}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        } : null
      }>

        <div className="mt-sm-5 mt-0 p-sm-5 p-3 trip-process-form">
          <Form noValidate onSubmit={handleSubmit(nextStep)}>
            <Form.Group className="col">
              <Form.Label className="heading-text">
                {t('pages.trips.form.labels.when.how_long')}
              </Form.Label>
              <Form.Control
                as="select"
                {...register('how_long')}
                isInvalid={!!errors.how_long}
              >
                <option value="" disabled selected>
                  {t('common.choose')}
                </option>
                {
                  [...Array(30)].map((number, index) => {
                    return (
                      <option
                        value={index + 1}
                        key={index}
                      >
                        {t('pages.trips.form.labels.when.night', { count: (index + 1) })}
                      </option>
                    )
                  })
                }
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                {errors.how_long && errors.how_long.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col">
              <Form.Check
                  inline
                  label={t('pages.trips.form.labels.when.allow_flexi_duration')}
                  name="allow_flexi_duration"
                  type="checkbox"
                  {...register('allow_flexi_duration')}
              />

              <Form.Control.Feedback type="invalid">
                {errors.allow_flexi_duration && errors.allow_flexi_duration.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col">
              <hr/>
            </Form.Group>
            {
              !bySpecificDate ?
                <>

                  <Form.Group className="col">
                    <Form.Label className="heading-text">
                      {t('pages.trips.form.labels.dates.months')}
                    </Form.Label>
                    <Row>
                      {
                        months.map((month, index) => {
                          return (
                            <Col className="col-4 d-grid gap-2 my-1" key={index}>
                              <Button
                                variant={(selectedMonths && selectedMonths.includes(moment(month).format(t('common.formats.month_year')))) ? 'primary' : 'light'}
                                className={`fw-bold text-uppercase month-btn`} onClick={() => addMonth(month)}>
                                {moment(month).format(t('common.formats.month_year'))}
                              </Button>
                            </Col>
                          )
                        })
                      }
                    </Row>
                    {
                      (errors.months && errors.months.message) &&
                      <div className="invalid-feedback d-block">{errors.months.message}</div>
                    }
                  </Form.Group>
                </>
                :
                <div className="text-center">
                  <Form.Group>
                    <Form.Label className="my-2">
                      {t('pages.trips.form.labels.dates.dates_label')}
                    </Form.Label>
                    <hr/>
                    <Calendar
                      ref={calendarRef}
                      range
                      value={dateValues}
                      onChange={setDateValues}
                      minDate={new DateObject().add(1, 'days')}
                      multiple
                    />
                    
                    {
                      (errors.request_dates && errors.request_dates.length !== 0) ?
                        <div className="invalid-feedback d-block">{getMessage(errors.request_dates)}</div> : null
                    }
                    <div className="d-grid">

                      <Button
                        disabled={dateValues?.length === 0}
                        variant="danger" className="text-white heading-text mt-3"
                        onClick={() => setDateValues([])}>
                        {t('pages.trips.steps.when.clear')}
                      </Button>

                      <Button
                          variant="dark"
                          className="text-white heading-text mt-3"
                          onClick={() => changeDateType(false)}>
                        {t('pages.trips.steps.when.back_to_all_dates')}
                      </Button>
                    </div>
                  </Form.Group>
                </div>
            }

            {
              !bySpecificDate ?
                <Form.Group className="col d-grid gap-2 mt-3">
                  <Button
                    variant="dark"
                    className="text-white heading-text"
                    onClick={() => changeDateType(true)}>
                    {t('pages.trips.steps.when.do_you_have_specific_dates')}
                  </Button>
                </Form.Group>
                :
                null
            }
            <Form.Group className="mt-4 d-grid gap-2">
              {
                modelEditor ?
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="secondary"
                      className="heading-text me-2"
                      onClick={() => handleCancel()}
                    >
                      {t('common.buttons.cancel')}
                    </Button>
                    <Button ref={continueActionRef} className="fw-bold heading-text" type="submit">
                      <span className="text-uppercase">{t('common.buttons.save')}</span>
                    </Button>
                  </div> :
                  <>
                    <Button ref={continueActionRef}
                            className={`fw-bold d-block heading-text`}
                            type="submit">
                      {t('common.buttons.continue')}
                    </Button>
                    <Link to={backStepLink}
                          className="btn btn-light-1 btn-lg fw-bold d-block mt-2 heading-text" type="button">
                      <span className="text-uppercase">{t('common.buttons.back')}</span>
                    </Link>
                  </>
              }
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  )
}

export default When