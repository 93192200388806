import login from './Login'
import signup from './Signup'
import forgot_password from './ForgotPassword'
import reset_password from './ResetPassword'
import travel_max_area from './TravelMaxArea'
import deals from './Deals'
import trips from './Trips'
import blog from './Blog'
import profile from './Profile'
import downloads from './Downloads'
import users from './Admin/Users'
import locations from './Admin/Locations'
import holidays from './Admin/Holidays'
import top_15_deals from './Admin/Top15Deals'
import destination_specific_deals from './Admin/DestinationSpecificDeals'
import travel_resources from './TravelResource'
import payments from './Payments'
import dashboard from './Admin/Dashboard'
import trip_requests from './Admin/TripRequests'
import subscription_management from './Admin/SubscriptionManagment'
import blacklist_hotels from './Admin/BlacklistHotels'

const pages = {
  login,
  signup,
  forgot_password,
  reset_password,
  travel_max_area,
  deals,
  trips,
  blog,
  profile,
  downloads,
  users,
  locations,
  holidays,
  top_15_deals,
  destination_specific_deals,
  travel_resources,
  payments,
  dashboard,
  trip_requests,
  subscription_management,
  blacklist_hotels,
}

export default pages