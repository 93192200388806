import { Form } from 'react-bootstrap'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import LoadingButton from '@/shared/components/LoadingButton'
import AppModal from '@/shared/components/AppModal'
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import LOCATION from "@/constants/Location";

function ManageUserAvatar ({ setUserProfileLink, baseUrl, userProfileLink }) {
    const { t } = useTranslation()
    const auth = useAuth()

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [serverErrors, setServerErrors] = useState([]);
    const [success, setSuccess] = useState(false);

    const schema = yup.object().shape({
        current_password: yup.string().required(t('common.form_validation.is_required', { attribute: 'Old Password' })),
        password: yup.string().required(t('common.form_validation.is_required', { attribute: 'New Password' })),
        password_confirmation: yup.string().required(t('common.form_validation.is_required', { attribute: 'Confirm New Password' })).oneOf([yup.ref('password'), null], t('common.form_validation.password_is_must_match'))
    })

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
        watch,
        reset
    } = useForm({
        resolver: yupResolver(schema),
    });

    const values = watch();

    const changePassword = () => {
        setLoading(true)
        auth.postRequest(`${LOCATION.USERS.API.path}/update-password`, values, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
                setLoading(false);
                setSuccess(true);
                setServerErrors([]);
                reset();
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setServerErrors([error.response.data.message])
                }

                if (error.response.status === 422) {
                    let serverErrors = []
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }

                    if (error.response.data?.message?.length > 0) {
                        serverErrors.push(error.response.data.message)
                    }

                    setServerErrors(serverErrors)
                }

                setLoading(false)
            })
    }

    const changePasswordRequest = () => {
        setShowModal(true)
    }

    const handleConfirm = () => {
        setShowModal(false);
        changePassword();
    }

    const handleCancel = () => {
        setShowModal(false);
    }

    return (
        <>
            <AppModal
                show={showModal}
                title="Change Password"
                body="Are you sure you want to change your password?"
                confirmText={t('common.dialogs.actions.yes')}
                cancelText={t('common.dialogs.actions.no')}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
            />
            <h4 className="heading-text">Change Password</h4>
            <Form noValidate className="mt-2" onSubmit={handleSubmit(changePasswordRequest)}>
                {
                    (serverErrors.length !== 0) &&
                    <div className="form-group mb-4">
                        {
                            serverErrors.map((error, index) =>
                                <p className="text-danger font-weight-bold"
                                   key={index}>{t(error)}</p>)
                        }
                    </div>
                }

                {
                    success &&
                    (
                        <div className="form-group mb-4">
                            <p className="text-success">Password changed successfully</p>
                        </div>
                    )
                }

                <Form.Group className="my-4" controlId="formBasicOldPassword">
                    <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
                        Old Password
                        <span className="text-danger ms-2 small">*</span>
                    </Form.Label>
                    <Form.Control
                        type="password"
                        placeholder={t('common.form.placeholder_enter', {attribute: 'Your Old Password'})}
                        {...register('current_password')}
                        isInvalid={!!errors.current_password}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.current_password && errors.current_password.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="my-4" controlId="formBasicNewPassword">
                    <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
                        New Password
                        <span className="text-danger ms-2 small">*</span>
                    </Form.Label>
                    <Form.Control
                        type="password"
                        placeholder={t('common.form.placeholder_enter', {attribute: 'Your New Password'})}
                        {...register('password')}
                        isInvalid={!!errors.password}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.password && errors.password.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="my-4" controlId="formBasicConfirmNewPassword">
                    <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
                        Confirm New Password
                        <span className="text-danger ms-2 small">*</span>
                    </Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Confirm Your New Password"
                        {...register('password_confirmation')}
                        isInvalid={!!errors.password_confirmation}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.password_confirmation && errors.password_confirmation.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="my-4 d-grid">
                    <LoadingButton
                        loading={loading}
                        variant="dark"
                        type="submit"
                        className="heading-text"
                        titleTranslationKey={t('pages.profile.buttons.validate_and_update')}
                    />
                </Form.Group>
            </Form>
        </>
    )
}

export default ManageUserAvatar