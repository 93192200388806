import LOCATION from '../../../constants/Location'
import BlacklistHotelsComponent from '../../../pages/Admin/BlacklistHotels';

const BlacklistHotels = [
    {
        path: LOCATION.BLACKLIST_HOTELS.LIST.path,
        component: BlacklistHotelsComponent,
        exact: true,
        main: false,
        gate: 'blacklist-hotels',
    },
]

export default BlacklistHotels;